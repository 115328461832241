<!-- 医疗机构生物样本库 -->
<template>
  <div class="bioBank">
    <banner-img :bannerImg="bannerImg" />
    <cases-title :titleVal="'医疗机构生物样本库管理系统'" />
    <Business :business="business" />
    <!-- 应用场景 -->
    <div class="apps-container">
      <div class="banxin">
        <Subhead :enVal="'Applications  '" :znVal="'应用场景'" />
        <div class="apps-of-img">
          <div class="apps-text">{{ appsData.text | myTrim }}<div class="know-more" @click="gotoAbout">了解更多</div>
          </div>
          <img class="apps-img" :src="appsData.imgUrl" alt="">
        </div>
      </div>
      <div class="blueBg">
        <div class="blueBg-container">
          <div class="left">
            <div class="zn">想了解更多关于宝晟？</div>
            <div class="en"> Bioshine?</div>
          </div>
          <img :src="AllowImg" alt="" class="right">
        </div>
      </div>
    </div>
    <!-- 产品案例 -->
    <div class="product-case">
      <cases-title :titleVal="'产品案例'" />
      <div class="product-content">
        <div class="product-item" v-for="(item, index) in productCaseData" :key="index">
          <div class="product-item-img">
            <img :src="item.imgUrl" alt="">
          </div>

          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
      <!--  -->
      <div class="user-container">
        <div class="user">系统用户包括：</div>
        <div class="user-desc">
          广东省中医院、中山大学附属第三医院、中山大学附属第五医院、南昌大学附属第二医院、香港中文大学威尔士亲王医院，广东省人民医院、广州医科大学附属肿瘤医院、广州医科大学附属第二医院，广州市第八人民医院，湖南省肿瘤医院、四川大学华西口腔医院、云南省人民医院、中信湘雅医院等70＋医疗机构。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bannerImg from "./components/BannerImg.vue"
import casesTitle from "./components/Title.vue"
import Business from "./components/Business.vue"
import Subhead from "./components/Subheading.vue"
export default {
  components: {
    bannerImg,
    casesTitle,
    Business,
    Subhead
  },
  data() {
    return {
      bannerImg: require("@/assets/images/cases/banner/banner7.png"),
      business: {
        text: "医疗机构生物样本库的样本种类多、病种多、临床信息数据多、环节多、关联科研项目多，不同机构基于自身对样本库的设计和运行模式不同，样本管理具有复杂的个性化的业务流程和信息数据管理需求，在遵循20387标准的前提下，要求信息管理系统能对样本全生命周期进行精细化管理。",
        Imgs: []
      },
      appsData: {
        text: "宝晟医疗机构生物样本库信息管理解决方案，采用成品软件和定制开发软件平台，为以各种形式运行的生物样本库实样本全生命周期进行精细化管理。从知情伦理管理，样本采集和运输管理，样本制备和存储运作管理，样本申请和分发管理，盘库和样本及数据质控管理，以及样本库人员、设备、耗材资源管理等。成品软件为运行模式相对简单，流程相对固定的样本库提供信息化管理，定制系统基于为样本库梳理甚至重塑业务流程，并根据样本库的应用设计，建设多中心样本库，共享平台，建立临床数据库、研究数据库等。",
        imgUrl: require("@/assets/images/cases/content/apps-bioBank01.png")
      },
      AllowImg: require("@/assets/images/cases/content/apps-bg-allow.png"),
      // activeImg: require("@/assets/images/cases/content/apps-bg-allow-active.png"),
      productCaseData: [
        {
          imgUrl: require("@/assets/images/cases/content/product-case01.png"),
          desc: "中山大学附属第五医院"
        },
        {
          imgUrl: require("@/assets/images/cases/content/product-case02.png"),
          desc: "广东省中医院"
        },
        {
          imgUrl: require("@/assets/images/cases/content/product-case03.png"),
          desc: "南昌大学附属第二医院"
        },
        {
          imgUrl: require("@/assets/images/cases/content/product-case04.png"),
          desc: "四川大学华西口腔医院"
        },
        {
          imgUrl: require("@/assets/images/cases/content/product-case05.png"),
          desc: "中山大学附属第三医院"
        },
        {
          imgUrl: require("@/assets/images/cases/content/product-case06.png"),
          desc: "广东省人民医院"
        },
      ]
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.bioBank {


  .apps-container {
    padding-top: 86px;
    position: relative;

    .apps-of-img {
      display: flex;
      justify-content: space-between;
      // margin-top: 67px;

      .apps-text {
        margin-top: 67px;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 37px;
        letter-spacing: 3px;
        text-align: justify;
        position: relative;

        .know-more {
          position: absolute;
          bottom: 200px;
          width: 136px;
          height: 44px;
          // margin-top: 36px;
          background: #2A5CE9;
          text-align: center;
          line-height: 44px;
          font-size: 18px;
          font-weight: 400;
          color: #FFFFFF;
          border-radius: 30px;
          cursor: pointer;
        }
      }

      .apps-img {
        display: block;
        width: 515px;
        height: 660px;
        margin-left: 85px;
      }
    }

    .blueBg {
      position: absolute;
      bottom: 0;
      margin: 0 auto;
      padding: 32px 0 33px;
      width: 100%;
      height: 127px;
      background: #2A5CE9;
      box-sizing: border-box;
      z-index: -1;
      // display: flex;
      // align-items: center;

      .blueBg-container {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;

        .left {
          .zn {
            height: 33px;
            font-size: 24px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 33px;
          }

          .en {
            height: 25px;
            font-size: 18px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 25px;
          }
        }

        .right {
          display: block;
          width: 37px;
          height: 37px;
          margin-left: 144px;
        }
      }
    }
  }

  .product-case {
    background: #f9f9f9;

    .product-content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      text-align: center;


      .product-item {
        width: 377px;

        .product-item-img {
          height: 226px;
          overflow: hidden;

          img {
            display: block;
            width: 100%;
            height: 100%;
            transition: all 1s;

            &:hover {
              transform: scale(1.5);
            }
          }
        }


        .desc {
          margin: 20px 0 40px;
          text-align: justify;
        }
      }
    }

    .user-container {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 77px;

      .user {
        margin-bottom: 13px;
        height: 30px;
        font-size: 22px;
        font-weight: bold;
        color: #000000;
        line-height: 30px;

      }

      .user-desc {
        font-size: 16px;
        color: #000000;
        line-height: 20px;
        text-align: justify;
      }
    }

  }


}
</style>